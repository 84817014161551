import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N200, N800, B200 } from '@atlaskit/theme/colors';
import type { SizeType } from '@atlaskit/avatar/types';
import { Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

import { ProfileAvatar, ProfileLink } from '@confluence/profile';
import { TimeAgo } from '@confluence/time-ago';
import { ExternalCollaboratorUserLozenge } from '@confluence/external-collab-ui';
import {
	InlineCommentAuthorQuery,
	SitePermissionTypeValues,
	UserQuery,
} from '@confluence/inline-comments-queries';
import type {
	SitePermissionType,
	AuthorQueryType,
	InlineCommentAuthorQuery_content_nodes_history_createdBy_KnownUser,
	InlineCommentAuthorQuery_content_nodes_history_createdBy_User,
	AuthorQueryVariables,
	UserQueryType,
	UserQueryVariables,
	CommentInlineResolveProperties,
} from '@confluence/inline-comments-queries';

import type { CommentMode } from './inlineCommentsTypes';
import { CopyLinkButton } from './CopyLinkButton';
import { i18n } from './inlineCommentsi18n';

type CommentAuthorProps = {
	commentMode: CommentMode;
	userId?: string | null;
	avatarUrl?: string | null;
	displayName?: string | null;
	date?: string | null;
	size?: SizeType;
	commentDateUrl?: string;
	commentId?: string | null;
	permissionType?: SitePermissionType;
	isInactiveComment?: boolean;
	showCopyLink?: boolean;
	isResolved?: boolean;
	resolveProperties?: CommentInlineResolveProperties;
};

type User =
	| InlineCommentAuthorQuery_content_nodes_history_createdBy_KnownUser
	| InlineCommentAuthorQuery_content_nodes_history_createdBy_User;

type AuthorContainerProps = {
	commentMode: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const AuthorContainer = styled.div<AuthorContainerProps>(`
  display: flex;
  align-items: center;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  margin-left: ${(props: AuthorContainerProps) =>
		props.commentMode === 'reply' ? '-2px' : 'inherit'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  padding-bottom: ${(props: AuthorContainerProps) =>
		props.commentMode !== 'view' ? '8px;' : undefined};

  > a {
    color: ${token('color.text', N800)} !important;

    &:visited,
    &:hover {
      color: ${token('color.text', N800)} !important;
      text-decoration: none !important;
    }
  }
  overflow: "hidden";
  `);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	display: 'flex',
	paddingRight: `${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-direction: column;
  > a {
    font-weight: 500;
    color: ${token('color.text', N800)} !important;
    line-height: 16px;
    &:visited,
    &:hover {
      color: ${token('color.text', N800)} !important;
      text-decoration: none !important;
    }
    &:focus-visible {
      outline: 2px solid ${token('color.border.focused', B200)};
    }
  }
  > span.comment-timestamp {
    font-size: 11px;
    line-height: 16px;
    color: ${token('color.text.subtle', N200)};
    margin: 0;
    background-color: transparent;
    border: none;

    > a {
      color: ${token('color.text.subtle', N200)};

      &:visited,
      &:hover {
        color: ${token('color.text.subtle', N200)};
      }
    }
  }
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfileLinkContainer = styled(Container)({
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	paddingLeft: `${token('space.100', '8px')}`,
	verticalAlign: 'top',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateContainer = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > span {
    font-size: 11px;
    line-height: 16px;
    color: ${token('color.text.subtle', N200)};
    margin: 0;
    background-color: transparent;
    border: none;

    > a {
      color: ${token('color.text.subtle', N200)};

      &:visited,
      &:hover {
        color: ${token('color.text.subtle', N200)};
      }
    }
  }

  > div {
    button:focus {
      box-shadow: 0 0 0 2px ${token('color.border.focused', B200)};
    }
  }
`,
);

export const CommentAuthor: FC<CommentAuthorProps> = ({
	commentMode,
	userId,
	date,
	size,
	displayName,
	avatarUrl,
	commentDateUrl,
	commentId,
	permissionType,
	isInactiveComment,
	showCopyLink = false,
	isResolved = false,
	resolveProperties,
}) => {
	const { data: authorData } = useQuery<AuthorQueryType, AuthorQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineCommentAuthorQuery,
		{
			variables: { commentId: commentId! },
			fetchPolicy: 'cache-and-network',
			skip: Boolean(userId || !commentId),
		},
	);

	const { data: userData } = useQuery<UserQueryType, UserQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UserQuery,
		{
			variables: { id: userId! },
			fetchPolicy: 'cache-and-network',
			// Skip the query if data is provided or the user is anonymous
			skip: Boolean((avatarUrl && displayName) || !userId || commentId),
		},
	);

	const author = (authorData?.content?.nodes || [])[0]?.history?.createdBy;
	const userAvatar = (
		author ? author.profilePicture?.path : (userData?.user?.photos || [])[0]?.value
	) as string | undefined;
	const userDisplayName = author ? author.displayName : userData?.user?.displayName;
	const userAccountId = (author as User)?.accountId;

	const linkToComment = `${window.location.origin}/wiki${commentDateUrl}`;
	const { formatMessage } = useIntl();

	const renderCopyButton = () => {
		return (
			<DateContainer>
				{isResolved ? (
					<Tooltip content={resolveProperties?.resolvedFriendlyDate}>
						<Text color="color.text.subtlest" size="small" maxLines={2}>
							{formatMessage(i18n.resolvedByInfoText, {
								username: resolveProperties?.resolvedUser,
								friendlyDate: resolveProperties?.resolvedFriendlyDate,
							})}
						</Text>
					</Tooltip>
				) : (
					<span>
						<TimeAgo date={date!} />
					</span>
				)}

				{commentDateUrl && <CopyLinkButton visible={showCopyLink} textToCopy={linkToComment} />}
			</DateContainer>
		);
	};

	const isDateShown = commentMode === 'view' && date;
	const name = displayName || userDisplayName || '';
	return (
		<AuthorContainer commentMode={commentMode} data-cy="comment-author">
			<AvatarWrapper>
				<ProfileAvatar
					userId={userId}
					src={avatarUrl || userAvatar}
					trigger="click"
					size={size}
					name={name}
				/>
			</AvatarWrapper>
			<Container>
				<ProfileLinkContainer>
					{/* Show Dummy Link in Inactive Mode for View-All*/}
					{isInactiveComment ? (
						<span data-testid="dummy-profile-link">{displayName || userDisplayName}</span>
					) : (
						<ProfileLink
							fullName={(displayName || userDisplayName)!}
							userId={userId || userAccountId}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ wordBreak: 'break-word', color: token('color.text', N800), fontWeight: 500 }}
						/>
					)}
					{permissionType === SitePermissionTypeValues.EXTERNAL ? (
						<LozengeWrapper>
							<ExternalCollaboratorUserLozenge />
						</LozengeWrapper>
					) : null}
				</ProfileLinkContainer>
				{isDateShown && renderCopyButton()}
			</Container>
		</AuthorContainer>
	);
};
